import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { Testing } from '~/components/Testing';

const TestingPage: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="Testing.pageTitle" />
    <Testing />
  </>
);

export default TestingPage;
